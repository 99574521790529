// importando Axios
import axios from 'axios';
import Vue from 'vue';
// creacion de la instancia de Axios para la API de InfoFeed
const axiosInfoFeed = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
});
//interceptor de Axios para inlcuir el header Authorization
axiosInfoFeed.interceptors.request.use(
	function(config) {
		//const token = sessionStorage.getItem('token');
		const token = sessionStorage.getItem('token') != null ? sessionStorage.getItem('token'):localStorage.getItem('token');
		if (token) {
			config.headers.common['Authorization'] = 'Bearer ' + token;
		} else {
			delete config.headers.common['Authorization'];
		}
		return config;
	},
	function(error) {
		return Promise.reject(error);
	}
);
// interceptores para progress bar
axiosInfoFeed.interceptors.request.use((config) => {
	Vue.prototype.$Progress.start(); // for every request start the progress
	return config;
});
axiosInfoFeed.interceptors.response.use(
	(response) => {
		Vue.prototype.$Progress.finish();
		return response;
	},
	(error) => {
		Vue.prototype.$Progress.fail();
		return Promise.reject(error);
	}
);
// creacion de la instancia de Axios para la API de Instagram
// const axiosInstagram = axios.create({
// 	baseURL: process.env.VUE_APP_API_INSTAGRAM,
// });

// export default { axiosInfoFeed, axiosInstagram };
export default axiosInfoFeed;
