//import axios from 'axios';
import axiosInfoFeed from '../axiosConfig';
import tokenService from './token.service';
//import VueProgressBar from 'vue-progressbar';
import Vue from 'vue';

class AuthService {
	async login(user) {
		const response = await axiosInfoFeed.post('/api/v1/login', {
			email: user.username,
			password: user.password,
		});
		let userType = process.env.VUE_APP_EMPLOYEE;
		if (response.data.data === undefined) {
			sessionStorage.setItem('userTokens', JSON.stringify(response.data));
			sessionStorage.setItem('token', response.data.access_token);
		} else {
			sessionStorage.setItem(
				'userData',
				JSON.stringify(response.data.data)
			);
			sessionStorage.setItem('token', response.data.data.token);
			userType = process.env.VUE_APP_CUSTOMER;
		}
		sessionStorage.setItem('userType', userType);
		sessionStorage.setItem('logged', true);
		return userType;
		//return response.data;
	}
	async refreshToken() {
		const response = await axiosInfoFeed.post('/api/v1/refreshtoken', {
			refresh_token: tokenService.getLocalRefreshToken(),
		});
		return response.data;
	}
	async getDataUser() {
		const response = await axiosInfoFeed.get('api/v1/userloggedin');
		sessionStorage.setItem('userData', JSON.stringify(response.data.data));
		return response.data.data;
	}
	async logout() {
		const response = await axiosInfoFeed.post('/api/v1/logout');
		return response.data;
	}
}

export default new AuthService();
