class TokenService {
	getLocalRefreshToken() {
		const user = JSON.parse(sessionStorage.getItem('userTokens'));
		return user?.refresh_token;
	}

	getLocalAccessToken() {
		const user = JSON.parse(sessionStorage.getItem('userTokens'));
		return user?.access_token;
	}

	updateLocalAccessToken(token) {
		sessionStorage.setItem('userTokens', JSON.stringify(token));
		sessionStorage.setItem('token', token.access_token);
	}

	getUser() {
		return JSON.parse(sessionStorage.getItem('userTokens'));
	}

	setUser(user) {
		sessionStorage.setItem('userTokens', JSON.stringify(user));
	}

	removeUser() {
		sessionStorage.removeItem('userTokens');
	}
}

export default new TokenService();
