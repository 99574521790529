//core de VueJs
import Vue from 'vue';

//core de fontawesome para VueJs
import { library } from '@fortawesome/fontawesome-svg-core';

// importacion del plugin de fontawesome para Vue 2.x
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/*funcionalidad para poder usar la etiqueta HTML <i></i> y que
sea convertida a SVg por fontawesome*/

import { dom } from '@fortawesome/fontawesome-svg-core';

//para importar iconos solidos
import {
	faTrashAlt,
	faTrash,
	faEnvelope,
	faLock,
	faCheckDouble,
	faDizzy,
	faSadCry,
	faSadTear,
	faGrinStars,
	faSignOutAlt,
	faTachometerAlt,
	faHome,
	faMailBulk,
	faPaperPlane,
	faUsers,
	faClipboard,
	faInbox,
	faEnvelopeOpenText,
	faChartArea,
	faChartBar,
	faChartLine,
	faChartPie,
	faUser,
	faUserAlt,
	faUserAltSlash,
	faUserAstronaut,
	faUserCheck,
	faUserEdit,
	faUserTie,
	faAt,
	faUserPlus,
	faIdBadge,
	faSmileWink,
	faExclamation,
	faExclamationCircle,
	faExclamationTriangle,
	faHandPointDown,
	faHandPointLeft,
	faHandPointRight,
	faHandPointUp,
	faHandPointer,
	faEye,
	faTimes,
	faTimesCircle,
	faPowerOff,
	faTasks,
	faEdit,
	faBan,
	faAddressBook,
	faUserTimes,
	faHistory,
	faSearch,
	faCheckCircle,
	faCheckSquare,
	faBook
} from '@fortawesome/free-solid-svg-icons';

// paraimportar iconos regulares de ser necesario
/*import {
    iconName
} from '@fortawesome/free-regular-svg-icons'*/

import { faInstagram } from '@fortawesome/free-brands-svg-icons';

//los iconos importados se deben añadir aqui con la instrucion library.add(iconName)
library.add(
	faTrashAlt,
	faTrash,
	faEnvelope,
	faLock,
	faCheckDouble,
	faDizzy,
	faSadCry,
	faSadTear,
	faGrinStars,
	faSignOutAlt,
	faTachometerAlt,
	faHome,
	faMailBulk,
	faPaperPlane,
	faUsers,
	faClipboard,
	faInbox,
	faEnvelopeOpenText,
	faChartArea,
	faChartBar,
	faChartLine,
	faChartPie,
	faUser,
	faUserAlt,
	faUserAltSlash,
	faUserAstronaut,
	faUserCheck,
	faUserEdit,
	faUserTie,
	faAt,
	faUserPlus,
	faIdBadge,
	faSmileWink,
	faExclamation,
	faExclamationCircle,
	faExclamationTriangle,
	faHandPointDown,
	faHandPointLeft,
	faHandPointRight,
	faHandPointUp,
	faHandPointer,
	faEye,
	faTimes,
	faTimesCircle,
	faPowerOff,
	faTasks,
	faInstagram,
	faEdit,
	faBan,
	faAddressBook,
	faUserTimes,
	faHistory,
	faSearch,
	faCheckCircle,
	faCheckSquare,
	faBook
);
dom.watch();

Vue.component('font-awesome-icon', FontAwesomeIcon);
