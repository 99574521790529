import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import App from './App';
import router from './router';
import CoreuiVue from '@coreui/vue';
import { iconsSet as icons } from './assets/icons/icons.js';
// import store from './store'
import store from './store/index';
import titleMixin from './mixins/titleMixin';
import VueProgressBar from 'vue-progressbar'; //vue-progressbar (https://github.com/hilongjw/vue-progressbar)
import { LoadingScreen } from './components/indexComponents';
Vue.component('LoadingScreen', LoadingScreen);
/* <----------- vee-validate (https://logaretm.github.io/vee-validate/) -----------> */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { setInteractionMode } from 'vee-validate';
setInteractionMode('lazy');
import { localize } from 'vee-validate';
import es from 'vee-validate/dist/locale/es.json';
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
//activacion de idioma para vee-validate
localize('es', es);
/* <----------- bootstrap-vue (https://bootstrap-vue.org/) -----------> */
import {
	BootstrapVue,
	//IconsPlugin
} from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
Vue.use(BootstrapVue);
//Vue.use(IconsPlugin);
/* <----------- plugins -----------> */
require('./plugins/fontAwesome');
/* <----------- mixins -----------> */
Vue.mixin(titleMixin);
/* <----------- vue-progressbar -----------> */
Vue.use(VueProgressBar, {
	color: '#97CA3D',
	failedColor: 'red',
	height: '10px',
	thickness: '10px',
});
/* <----------- vue-autocomplete (https://autocomplete.trevoreyre.com/#/vue-component) -----------> */
import Autocomplete from '@trevoreyre/autocomplete-vue';
import '@trevoreyre/autocomplete-vue/dist/style.css';
Vue.use(Autocomplete);
/* <----------- vue-multiselect (https://vue-multiselect.js.org/) -----------> */
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
Vue.component('multiselect', Multiselect);
//libreria momentjs
import moment from 'moment';
moment.locale('es-us');
Vue.prototype.$moment = moment;

// import setupInterceptors from './services/setupInterceptors';
// setupInterceptors(store);

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.prototype.$log = console.log.bind(console);

new Vue({
	el: '#app',
	router,
	store,
	icons,
	template: '<App/>',
	components: {
		App,
	},
});
