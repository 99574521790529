<template>
	<div>
		<CRow>
			<CCol sm="12" style="margin-top: 2%; font-family: Montserrat-Bold;">
				<h1 class="d-none d-sm-block green-text">
					¡LISTO!
				</h1>
				<h3 class="d-block d-sm-none green-text">
					¡LISTO!
				</h3>
			</CCol>
		</CRow>
		<br />
		<CRow>
			<CCol sm="12">
				<img
					class="d-none d-sm-block"
					style="width: 60%; margin-left: auto; margin-right: auto;"
					:src="this.images.successAuth"
					alt="proceso cancelado"
				/>
				<img
					class="d-block d-sm-none"
					style="width: 40%; margin-left: auto; margin-right: auto;"
					:src="this.images.successAuth"
					alt="proceso cancelado"
				/>
			</CCol>
		</CRow>
		<br />
		<CRow class="content-center">
			<CCol sm="12">
				<p class="h5" style="font-family: 'Montserrat-Bold';">
					¡Tu widget ha sido activado con &eacute;xito!
				</p>
			</CCol>
		</CRow>
		<CRow class="content-center">
			<CCol sm="12">
				<p class="h5" style="font-family: 'Montserrat-Regular';">
					Visita tu perfil de
					<span style="font-family: 'Montserrat-Bold';"
						>Infoguia</span
					>
					y visual&iacute;zalo
				</p>
			</CCol>
		</CRow>
		<br />
	</div>
</template>

<script>
	export default {
		name: 'SuccessAuth',
		title: 'Bienvenido Infoamigo | Infoguia',
		props: {
			propName: {
				type: Number,
				default: 0,
			},
		},
		beforeCreate() {},
		created() {},
		data() {
			return {
				images: {
					successAuth: process.env.VUE_APP_SUCCESS_ACTIVATION,
				},
			};
		},
	};
</script>
