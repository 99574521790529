<template>
	<div>
		<CRow>
			<CCol sm="12" style="margin-top: 2%;">
				<h3>
					<span style="font-family: Montserrat-Bold"
						>Ha ocurrido un error
					</span>
				</h3>
			</CCol>
		</CRow>
		<br />
		<CRow>
			<CCol sm="12">
				<img
					style="width: 35%"
					:src="this.images.error"
					alt="comencemos"
				/>
			</CCol>
		</CRow>
		<br />
		<CRow>
			<CCol sm="12">
				<p class="h5" style="font-family: 'Montserrat-Bold';">
					Lamentamos que tu solicitud no haya podido ser procesada
				</p>
			</CCol>
		</CRow>
		<CRow class="content-center">
			<CCol sm="6">
				<p class="h6 black-box" style="padding-left: 2%">
					Por favor, comun&iacute;quese a la brevedad posible con su
					ejecutivo de ventas o env&iacute;a un correo con tu
					situaci&oacute;n a
					<span style="color: #FFF100;"
						>atencionalcliente@infoguia.com</span
					>
				</p>
			</CCol>
		</CRow>
		<CRow v-if="this.errorMessage !== ''" class="content-center">
			<CCol sm="6">
				<p class="h8 border-green-box" style="padding-left: 2%">
					Error: {{ this.errorMessage }}
				</p>
			</CCol>
		</CRow>
	</div>
</template>

<script>
	export default {
		name: 'FailAuth',
		title: 'Bienvenido Infoamigo | Infoguia',
		props: {
			errorMessage: {
				type: String,
				default: '',
			},
		},
		beforeCreate() {},
		created() {},
		data() {
			return {
				images: {
					error: process.env.VUE_APP_ERROR_ACTIVATION,
				},
			};
		},
	};
</script>
