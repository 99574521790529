<template>
	<div>
		<CRow>
			<CCol sm="12" style="margin-top: 2%; font-family: Montserrat-Bold;">
				<h3 class="d-none d-sm-block">
					Tu solicitud est&aacute; siendo procesada
				</h3>
				<h5 class="d-block d-sm-none">
					Tu solicitud est&aacute; siendo procesada
				</h5>
			</CCol>
		</CRow>
		<br />
		<!-- <CRow>
			<CCol sm="12">
				<img
					class="img-fluid ld ld-breath"
					:src="this.images.avatarLoading"
					width="200"
				/>
			</CCol>
		</CRow> -->
		<CRow>
			<CCol sm="12">
				<img
					style="width: 70%; margin-left: auto; margin-right: auto;"
					:src="this.images.processingAuth"
					alt="proceso cancelado"
				/>
				<!-- <img
					class="d-none d-sm-block"
					style="width: 70%; margin-left: auto; margin-right: auto;"
					:src="this.images.processingAuth"
					alt="proceso cancelado"
				/>
				<img
					class="d-block d-sm-none"
					style="width: 70%; margin-left: auto; margin-right: auto;"
					:src="this.images.processingAuth"
					alt="proceso cancelado"
				/> -->
			</CCol>
		</CRow>
	</div>
</template>

<script>
	export default {
		name: 'ProcessingAuth',
		title: 'Bienvenido Infoamigo | Infoguia',
		props: {},
		beforeCreate() {},
		created() {},
		data() {
			return {
				images: {
					processingAuth: process.env.VUE_APP_PROCESSING,
					avatarLoading:
						process.env.VUE_APP_AVATAR_TRANSPARENT_INFOGUIA,
				},
			};
		},
	};
</script>
