import Vue from 'vue';
import Router from 'vue-router';
import { userProfiles } from '../constants/profilesUsers';

// Containers
const TheContainer = () => import('@/containers/TheContainer');
const TheContainerClients = () => import('@/containers/TheContainerClients');

//error 4040

const PageNotFound = () => import('@/views/page404');

// dashboard
const Dashboard = () => import('@/views/Dashboard');
//modulo de usuarios
const NewUser = () => import('@/views/users/NewUser');
const AllUsers = () => import('@/views/users/AllUsers');
const EditUser = () => import('@/views/users/editUser');
//modulos para los widgets
const RequestAuth = () => import('@/views/requests/requestAuth');
const PendingAuth = () => import('@/views/requests/pendingAuth');
const HistoryAuths = () => import('@/views/requests/historyAuths');
//modulo para reportes
const ReportWithoutWidget = () => import('@/views/reports/withoutWidget');
const ReportRequestsSent = () => import('@/views/reports/requestsSent');

//Rutas para el login y el logout
const Login = () => import('@/views/Login');
const Logout = () => import('@/views/Logout');

//clientes de infoguia
const Welcome = () => import('@/views/customers/welcome');
const Authorizing = () => import('@/views/customers/auth');
const DeleteData = () => import('@/views/customers/deleteData');

Vue.use(Router);

const router = new Router({
	mode: 'history', // https://router.vuejs.org/api/#mode
	linkActiveClass: 'active',
	scrollBehavior: () => ({ y: 0 }),
	routes: configRoutes(),
});

function configRoutes() {
	return [
		{
			path: '/',
			redirect: {
				name: 'Login',
			},
		},
		//login
		{
			path: '/login',
			name: 'Login',
			component: Login,
		},
		//logout
		{
			path: '/logout',
			name: 'logout',
			component: Logout,
			meta: {
				requiresAuth: true,
			},
		},
		// ruta para 404
		{
			path: '*',
			//path: '404',
			//name: 'pageNotFound',
			redirect: '/404',
			component: TheContainer,
			children: [
				{
					path: '404',
					name: 'pageNotFound',
					component: PageNotFound,
					meta: {
						label: 'Ooops!! página no encontrada',
					},
				},
			],
		},
		//rutas principales del sistema
		{
			path: '/',
			redirect: '/dashboard',
			name: 'Home',
			component: TheContainer,
			meta: {
				requiresAuth: true,
				onlyEmployee: true
			},
			children: [
				//dashboard del sistema
				{
					path: 'dashboard',
					name: 'Dashboard',
					component: Dashboard,
				},
				//rutas para el modulo de usuarios
				{
					path: '/users',
					redirect: '/users/consult',
					meta: {
						label: 'Usuarios',
						onlyThisRoles: [
							userProfiles.adminUser,
							userProfiles.coordinator,
						],
					},
					component: {
						render(c) {
							return c('router-view');
						},
					},
					children: [
						{
							path: '/users/newUser',
							name: 'NewUser',
							meta: {
								label: 'Crear Nuevo Usuario',
								onlyThisRoles: [
									userProfiles.adminUser,
									userProfiles.coordinator,
								],
							},
							component: NewUser,
						},
						{
							path: '/users/consult',
							name: 'AllUsers',
							meta: {
								label: 'Consultar Usuarios',
								onlyThisRoles: [
									userProfiles.adminUser,
									userProfiles.coordinator,
								],
							},
							component: AllUsers,
						},
						{
							path: '/users/editUser',
							name: 'EditUser',
							props: true,
							meta: {
								label: 'Editar Usuario',
								onlyThisRoles: [
									userProfiles.adminUser,
									userProfiles.coordinator,
								],
							},
							component: EditUser,
							beforeEnter: (to, from, next) => {
								if (to.params.userId) {
									next();
								} else {
									next({ name: 'AllUsers' });
								}
							},
						},
					],
				},
				//rutas para el proceso del widget
				{
					path: '/requests',
					redirect: '/requests/pendigAuth',
					meta: {
						label: 'Administrar Widgets',
					},
					component: {
						render(c) {
							return c('router-view');
						},
					},
					children: [
						{
							path: '/requests/requestAuth',
							name: 'RequestAuth',
							meta: {
								label: 'Solicitar Autorización',
							},
							component: RequestAuth,
						},
						{
							path: '/requests/pendigAuth',
							name: 'PendingAuth',
							meta: {
								label: 'Por Autorizar',
							},
							component: PendingAuth,
						},
						{
							path: '/requests/historyAuths',
							name: 'HistoryAuths',
							meta: {
								label: 'Historial de Autorizaciones',
							},
							component: HistoryAuths,
						},
					],
				},
				//rutas para los reportes
				{
					path: '/reports',
					redirect: '/reports/withoutWidget',
					meta: {
						label: 'Reportes',
					},
					component: {
						render(c) {
							return c('router-view');
						},
					},
					children: [
						{
							path: '/reports/withoutWidget',
							name: 'ReportWithoutWidget',
							meta: {
								label: 'Clientes Sin Widget',
							},
							component: ReportWithoutWidget,
						},
						{
							path: '/reports/requestsSent',
							name: 'ReportRequestsSent',
							meta: {
								label: 'Solicitudes Enviadas',
							},
							component: ReportRequestsSent,
						},
					],
				},
			],
		},
		//rutas de las vistas de los clientes
		{
			path: '/customers',
			redirect: '/customers/welcome',
			name: 'Clients',
			component: TheContainerClients,
			children: [
				{
					path: 'welcome',
					name: 'Welcome',
					component: Welcome,
				},
				{
					path: 'auth',
					name: 'Auth',
					component: Authorizing,
				},
				{
					path: 'deleteData',
					name: 'DeleteData',
					component: DeleteData,
					meta:{
						onlyCustomers: true
					}
				},
			],
		},
	];
}

router.beforeEach((to, from, next) => {
	const publicPages = ['/login', '/customers/welcome', '/customers/auth/'];
	const requireRole = to.matched.some((record) => record.meta.onlyThisRoles);
	const authRequired = !publicPages.includes(to.path);
	const loggedIn = sessionStorage.getItem('logged');
	const userType = sessionStorage.getItem('userType');
	let role = 0
	if (userType === process.env.VUE_APP_EMPLOYEE && loggedIn) {
		role = JSON.parse(sessionStorage.getItem('userData')).id_profiles;
	}
	if (authRequired && !loggedIn) {
		next({ name: 'Login' });
	} else {
		switch (userType) {
			case process.env.VUE_APP_EMPLOYEE:
				if (requireRole && !to.meta.onlyThisRoles.includes(role) || to.matched.some(m => m.meta.onlyCustomers)) {
					next({ name: 'Dashboard' });
				}
			break;
			case process.env.VUE_APP_CUSTOMER:
				if (to.matched.some(m => m.meta.onlyEmployee)) {
					next({ name: 'DeleteData' });
				}
			break;
		}
		next();
	}
});
export default router;
