import AuthService from '../services/auth.service';
import UserService from '../services/user.service';
import tokenService from '../services/token.service';

const user = JSON.parse(sessionStorage.getItem('user'));
const initialState = user
	? { status: { loggedIn: true }, user }
	: { status: { loggedIn: false }, user: null };

export const auth = {
	namespaced: true,
	state: initialState,
	actions: {
		login({ commit }, user) {
			return AuthService.login(user).then(
				(user) => {
					commit('loginSuccess', user);
					return Promise.resolve(user);
				},
				(error) => {
					commit('loginFailure');
					return Promise.reject(error);
				}
			);
		},
		getDataUser({ commit }) {
			return AuthService.getDataUser().then(
				(dataUser) => {
					commit('dataUser', dataUser);
					return Promise.resolve(dataUser);
				},
				(error) => {
					//commit('loginFailure');
					return Promise.reject(error);
				}
			);
		},
		logout({ commit }) {
			return AuthService.logout().then(
				(response) => {
					sessionStorage.clear();
					commit('logout');
					return Promise.resolve(response);
				},
				(error) => {
					sessionStorage.clear();
					commit('logout');
					return Promise.reject(error);
				}
			);
		},
		register({ commit }, user) {
			return AuthService.register(user).then(
				(response) => {
					commit('registerSuccess');
					return Promise.resolve(response.data);
				},
				(error) => {
					commit('registerFailure');
					return Promise.reject(error);
				}
			);
		},
		refreshToken({ commit }, accessToken) {
			commit('refreshToken', accessToken);
		},
		refreshTokenTest({ commit }) {
			return AuthService.refreshToken().then(
				(response) => {
					const { access_token } = response;
					commit('refreshTokenTest', access_token);
					tokenService.updateLocalAccessToken(response);
					return Promise.resolve(response);
				},
				(error) => {
					commit('logout');
					return Promise.reject(error);
				}
			);
		},
	},
	mutations: {
		loginSuccess(state, user) {
			state.status.loggedIn = true;
			state.user = user;
		},
		dataUser(state, dataUser) {
			state.dataUser = dataUser;
		},
		loginFailure(state) {
			state.status.loggedIn = false;
			state.user = null;
		},
		logout(state) {
			state.status.loggedIn = false;
			state.user = null;
		},
		registerSuccess(state) {
			state.status.loggedIn = false;
		},
		registerFailure(state) {
			state.status.loggedIn = false;
		},
		refreshToken(state, accessToken) {
			state.status.loggedIn = true;
			state.user = { ...state.user, accessToken: accessToken };
		},
		refreshTokenTest(state, accessToken) {
			state.status.loggedIn = true;
			state.user = { ...state.user, accessToken: accessToken };
		},
	},
};
