<template>
	<div>
		<CRow>
			<CCol sm="12" style="margin-top: 2%; font-family: Montserrat-Bold;">
				<h3 class="d-none d-sm-block">
					Proceso de autorizaci&oacute;n cancelado
				</h3>
				<h4 class="d-block d-sm-none">
					Proceso de autorizaci&oacute;n cancelado
				</h4>
			</CCol>
		</CRow>
		<br />
		<CRow>
			<CCol sm="12">
				<img
					style="width: 35%"
					:src="this.images.cancelAuth"
					alt="proceso cancelado"
				/>
			</CCol>
		</CRow>
		<br />
		<CRow>
			<CCol sm="12">
				<p class="h5" style="font-family: 'Montserrat-Bold';">
					Has cancelado el proceso de activaci&oacute;n de tu widget
					de Instagram
				</p>
			</CCol>
		</CRow>
		<br />
		<CRow>
			<CCol sm="12">
				<p
					class="h5"
					style="font-family: 'Montserrat-Regular'; text-align: left;"
				>
					Por ello, tus potenciales clientes no podr&aacute;n
					visualizar tu perfil de
					<span style="font-family: 'Montserrat-Bold'"
						>Instagram</span
					>
					en tiempo real, directamente desde nuestro portal.
				</p>
			</CCol>
		</CRow>
		<CRow
			class="content-center border-green-box"
			style="margin-left: 3%; margin-right: 3%;"
		>
			<CCol sm="12">
				<CRow>
					<CCol sm="12">
						<p class="h5 " style="padding-left: 2%">
							Te suguerimos iniciar el proceso nuevamente para que
							disfrutes de los siguientes beneficios:
						</p>
					</CCol>
				</CRow>
				<br />
				<CRow>
					<CCol sm="12">
						<p class="h5 " style="padding-left: 2%">
							<span style="color:#97CA3D;font-size: 20px;"
								><i
									class="fas fa-check-double"
									aria-hidden="true"
								></i
							></span>
							Mayor visibilidad
						</p>
						<p class="h5 " style="padding-left: 2%">
							<span style="color:#97CA3D;font-size: 20px;"
								><i
									class="fas fa-check-double"
									aria-hidden="true"
								></i
							></span>
							Gran Alcance
						</p>
						<p class="h5 " style="padding-left: 2%">
							<span style="color:#97CA3D;font-size: 20px;"
								><i
									class="fas fa-check-double"
									aria-hidden="true"
								></i
							></span>
							Aumento de seguidores
						</p>
						<p class="h5 " style="padding-left: 2%">
							<span style="color:#97CA3D;font-size: 20px;"
								><i
									class="fas fa-check-double"
									aria-hidden="true"
								></i
							></span>
							Aumento de los canales de comunicaci&oacute;n de
							confianza
						</p>
						<p class="h5 " style="padding-left: 2%">
							<span style="color:#97CA3D;font-size: 20px;"
								><i
									class="fas fa-check-double"
									aria-hidden="true"
								></i
							></span>
							Contacto con posibles clientes
						</p>
						<br />
						<CButton
							@click="clickAuth"
							block
							color="success"
							shape="pill"
							style="font-family: Montserrat-Bold;"
							>Activar mi widget</CButton
						>
					</CCol>
				</CRow>
			</CCol>
		</CRow>
		<br />
	</div>
</template>

<script>
	export default {
		name: 'CancelAuth',
		title: 'Bienvenido Infoamigo | Infoguia',
		props: {
			propName: {
				type: Number,
				default: 0,
			},
		},
		beforeCreate() {},
		created() {},
		data() {
			return {
				key: 0,
				images: {
					cancelAuth: process.env.VUE_APP_CANCEL_PERMISSIONS,
				},
				securityHash: sessionStorage.getItem('securityHash'),
			};
		},
		methods: {
			clickAuth() {
				window.location.replace(
					process.env.VUE_APP_API_INSTAGRAM +
						'?client_id=1452673848400484&redirect_uri=' +
						process.env.VUE_APP_URL +
						'/customers/auth/&scope=user_profile,user_media&response_type=code&state=' +
						this.securityHash
				);
			},
		},
	};
</script>
