<template>
	<div id="app">
		<router-view></router-view>
		<!-- set progressbar -->
		<vue-progress-bar></vue-progress-bar>
	</div>
</template>

<script>
	export default {
		name: 'App',
		mounted() {
			//  [App.vue specific] When App.vue is finish loading finish the progress bar
			this.$Progress.finish();
		},
		created() {
			//  [App.vue specific] When App.vue is first loaded start the progress bar
			this.$Progress.start();
			//  hook the progress bar to start before we move router-view
			this.$router.beforeEach((to, from, next) => {
				//  does the page we want to go to have a meta.progress object
				if (to.meta.progress !== undefined) {
					let meta = to.meta.progress;
					// parse meta tags
					this.$Progress.parseMeta(meta);
				}
				//  start the progress bar
				this.$Progress.start();
				//  continue to next page
				next();
			});
			//  hook the progress bar to finish after we've finished moving router-view
			this.$router.afterEach((to, from) => {
				//  finish the progress bar
				this.$Progress.finish();
			});
		},
		computed: {
			loggedIn() {
				if (sessionStorage.getItem('logged')) {
					return true;
				}
				return false;
				//return this.$store.state.auth.status.loggedIn;
			},
		},
		methods: {
			onidle() {
				alert('You have been logged out');
			},
		},
	};
</script>

<style lang="scss">
	// Import Main styles for this application
	@import 'assets/scss/style';
</style>
