<template>
	<div id="loading">
		<img
			class="imgLoading ld ld-breath"
			:src="this.avatarLoading"
			:alt="this.informationText"
			width="200"
		/>
		<p class="textLoading" v-html="informationText" />
	</div>
</template>

<script>
	export default {
		name: 'Loading',
		props: {
			informationText: {
				type: String,
				default: function() {
					return 'Procesando, por favor espere';
				},
			},
		},
		data() {
			return {
				avatarLoading: process.env.VUE_APP_AVATAR_TRANSPARENT_INFOGUIA,
			};
		},
	};
</script>